/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react"


import { SEO } from "../components/seo"
import { Heading, Box, Card, Link, Grid, Flex, Container } from '@theme-ui/components'
import { useFirebase } from 'gatsby-plugin-firebase'
import { IndexPage } from "../app";

const App = () => {

  return (
    <Container>
    </Container>
  )
}

export default App
